import React, {useState, useEffect, Fragment} from 'react'
import Loader from '../components/Loader/Loader';
import RegularHero from '../components/Hero-Section/RegularHero';
import UncuttedImg from '../components/Uncutted Image Content/UncuttedImg';
import RadialImgContent from '../components/Radial Image Content/RadialImgContent';
import CoachAssociations from '../components/Coaching Associations/CoachAssociations';
import CutImageContent from '../components/Cutted Image Content/CutImageContent';
import WeightLiftingAthlete from '../components/WeightLifting/WeightLiftingAthlete';
import { Athletics } from '../components/Constants/Athletic';
import { Helmet } from 'react-helmet';
import MembershipSlider from '../components/Memberships/MembershipSlider';
import { StrengthConditioningMembershipsArr } from '../components/Constants/ElementaryMemberships';

const StrengthConditioning = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const renderImageBasedOnMode = (currImage) => { 
        if(currImage == '/images/creatives/s_c_web.webp' && window.innerWidth <= 620) return '/images/creatives/s_c_mob.webp';
        return currImage;
    };

    const StrengthConditioningModule = () => {
        return (
            <Fragment> 
                <Helmet>
                    <title>Best Strength & Conditioning Gym in Ontario</title>
                    <meta name="keywords" content=" Strength and Conditioning Gym in Ontario"/>
                    <link rel="canonical" href="https://www.questsportscanada.club/strength-conditioning-gym" />
                    <meta name="description" content="Discover the best strength and conditioning program in Ontario! Our expert guides will help you achieve your fitness goals with personalized plans, nutrition advice, and much more"/>
                </Helmet>
                <div className='quest_sp_strength_conditioning_page'>
                    <RegularHero 
                        backgroundImage= {renderImageBasedOnMode('/images/creatives/s_c_web.webp')} 
                        paraStrengthConditioning={true} 
                        anotherText={'WEIGHTLIFTING'} 
                        text={'OLYMPIC'}
                        
                    />
                    <CutImageContent
                        programsRoute={true}
                        removeBtn={true}
                        marginTop= {true} 
                        heading={`WELCOME TO QUEST SPORTS STRENGTH & CONDITIONING`}
                        paragraph={`The Athletes Activation System from LPS Athletic, powers Quest Strength & Conditioning Gym. An internationally recognized system called Athlete Activation System (AAS) trusted by top coaches worldwide. This system maximizes and taps into athletes potential by activating higher threshold motor units.`}  
                        buttonTitle={`Sign Up`}
                        targetSection={'#strength_conditioning_membership_slider'}
                        listItems={['Stimulates Bone Density Growth', 'Strengthens Connective Tissue', 'Builds Confidence', 'Children Programs']}
                        dimensionsExtra={{width: '120px', height: '120px', marginTop: '0'}}
                        ExtraLogoBeforeBtn={true}
                        ExtraLogoPath={'/images/icons/vps.webp'}
                        swoosh={false}
                        imgUrl={`/images/creatives/img-content-13.png`} 
                    />
                    <RadialImgContent 
                        targetSection={'#strength_conditioning_membership_slider'}
                        programsRoute={true}
                        removeBtn={true} 
                        heading={`Join Quest Sports Track and Field Club and Experience Quality Athletic Development`}
                        paragraph={`The Quest Professional Athletic Group founded Quest Sports Inc. The group is comprised of current and former professional athletes, to Olympians, 
                        who unanimously believe that Canada has yet to tap into its true athletic talents. In an effort to introduce the Quest Training Systems to the world of 
                        sport, Quest Sports Inc. has set forth a bold and passionate plan to identify sports talent in children from the playgrounds of Canada. 
                        Our goal is to take them from their humble beginnings in the playground to the realization of their true athletic potential.`}
                        buttonTitle={`Sign Up`}
                        buttonRoute={'/appointment-booking'}
                        buttonIcon='/images/icons/golden-torch.svg'
                        hoverBtnIcon='/images/icons/black-torch.svg'
                        imgUrl={`/images/creatives/teenage-girl-professional-runner.webp`}
                    />
                    <UncuttedImg targetSection={'#strength_conditioning_membership_slider'} strengthConditioning={true} imgURL={`/images/creatives/uncutted-img-content-3.png`} /> 
                    <CutImageContent
                        removeBtn={true}
                        programsRoute={true} 
                        swoosh={false}
                        targetSection={'#strength_conditioning_membership_slider'}
                        listItems={Athletics}
                        strengthConditioningListItems={true}
                        // breakParagraph={`Young athletes can begin weightlifting at around seven to eight years old. They are trained in particularly designed manners. If they are mature enough to follow the right instructions, they are permissible to start weightlifting training.`}
                        heading={`360 ATHLETIC`} 
                        btnReq={true}
                        paragraph={` Speed, athletic movement and strength training underpin Quest Sports 360 Athletic Program. The program is designed by Olympians & The Pros for young athletes who desire a future in sports.  `}  
                        buttonTitle={`Sign Up`} 
                        reverse={'flex-row-reverse'}
                        imgUrl={`/images/creatives/img-content-12.png`} 
                    />
                    <MembershipSlider
                        id={'strength_conditioning_membership_slider'} 
                        backgroundImage={`/images/creatives/track-field-elementary-membership.png`} 
                        membershipsType={StrengthConditioningMembershipsArr} 
                        mainHeading={'STRENGTH & CONDITIONING MEMBERSHIPS'} 
                    />
                    <WeightLiftingAthlete />
                    <CoachAssociations 
                        heading={`Coaching ASSOCIATES`} 
                        imgURL1={`/images/creatives/coach-1.png`} 
                        imgURL2={`/images/creatives/coach-2.png`} 
                    />
                </div>
            </Fragment>
        )
    }
  return (
    <Fragment>
       {loading ? <Loader /> : StrengthConditioningModule()}
    </Fragment>
  )
}

export default StrengthConditioning;