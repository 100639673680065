import React, { useState } from 'react';
import './TeenageFlying.css';

const TeenageFlying = () => {
    const [btnHover, setBtnHover] = useState(false);

    const buttonIcon = '/images/icons/golden-torch.svg';
    const hoverBtnIcon = '/images/icons/black-torch.svg';
    return (
        <div className= {`teenage_takeoff`}>
            <div className='teenage_takeoff_content_component'>
                <div className='container-fluid p-0'>
                    <div className={`row d-flex m-0 ms-0 me-0 align-items-center gx-5`}>
                        <div className={`col-md-12 position-relative order-2 order-lg-1 col-12 col-lg-7 mt-3 `}>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-lg-1 col-0'></div>
                                    <div className='col-lg-11 col-12'>
                                        <h2>QUEST SPORTS TRACK & FIELD CLUB A HIDDEN GEM</h2>
                                        <p>Quest Sports Track & Field Club is a hidden gem that has emerged as the go to club for development, as we have produced some of the best young talent in Ontario! At Quest Sports we take commitment and development seriously as we mould, shape and nurture the Next Generation of Canadian Track & Field talent.</p>
                                        <a href='tel: +1-647-770-9124'>
                                            <button onMouseEnter={() => setBtnHover(true)} onMouseLeave={() => setBtnHover(false)} className='skew-btn'>
                                                INQUIRE NOW
                                                <span> 
                                                    <img className='img-fluid ml-2' src={!btnHover ? buttonIcon : hoverBtnIcon } />
                                                </span>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`col-md-12 hover-col p-0 m-0 order-1 d-flex justify-content-end align-items-center order-lg-2 col-12 col-lg-5 position-relative `}> 
                            <img src={'/images/creatives/teenage_takeoff.png'} className='img-fluid img-icon' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeenageFlying;