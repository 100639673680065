import React, { Fragment, useState, useEffect } from 'react'
import RegularHero from '../components/Hero-Section/RegularHero'
import RadialImgContent from '../components/Radial Image Content/RadialImgContent'
import BlogsComp from '../components/Blogs/BlogsComp'
import Loader from '../components/Loader/Loader' 
import { Helmet } from 'react-helmet'

const Blogs = () => {
    const [image, setImage] = useState('/images/creatives/blog-hero.jpg');
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }, []);
  
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 620) {
          setImage('/images/creatives/blog-hero-mob.png');
        } else {
          setImage('/images/creatives/blog-hero.jpg');
        }
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const BlogsModule = () => {
        return (
        <Fragment>
          <Helmet>
            <title>Read and learn from our experience </title>
            <meta name="keywords" content="Blogs"></meta>
            <meta name="description" content="Explore the world of sports through insightful blogs from Quest Sports Academy. Gain valuable knowledge, training tips, and expert advice to enhance your performance and stay ahead of the game. Fuel your passion for sports with our engaging and informative blog content." />
          </Helmet>
          <div className='quest_sp_blogs_page'>
            <RegularHero 
              middleHighlightedText={true}
              highlightedText={`&`}
              backgroundImage= {image}  
              text={'ARTICLES'}  
              anotherText={`BLOGS`}
            />
            <BlogsComp />
            <RadialImgContent 
              heading={`Join Quest Sports Track and Field Club and Experience Quality Athletic Development`}
              paragraph={`The Quest Professional Athletic Group founded Quest Sports Inc. The group is comprised of current and former professional athletes, to Olympians, 
              who unanimously believe that Canada has yet to tap into its true athletic talents. In an effort to introduce the Quest Training Systems to the world of 
              sport, Quest Sports Inc. has set forth a bold and passionate plan to identify sports talent in children from the playgrounds of Canada. 
              Our goal is to take them from their humble beginnings in the playground to the realization of their true athletic potential.`}
              buttonTitle={`Inquire Now`}
              buttonRoute={'/appointment-booking'}
              buttonIcon='/images/icons/golden-torch.svg'
              hoverBtnIcon='/images/icons/black-torch.svg'
              imgUrl={`/images/creatives/teenage-girl-professional-runner.webp`}
            />
          </div>
        </Fragment>
        )
    }

  return (
    <Fragment>
        { loading ? <Loader /> : (BlogsModule()) }
    </Fragment>
  )
}

export default Blogs;