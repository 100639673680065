import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../Loader/Loader'; 
import RegularHero from '../Hero-Section/RegularHero';
import RadialImgContent from '../Radial Image Content/RadialImgContent';
import { BlogsList } from './AllBlogs';
import BlogInnerWrapper from './BlogInnerWrapper';
import { Helmet } from 'react-helmet';
import axios from 'axios';

const InnerBlog = () => {
  const [blogData, setBlogData] = useState(null)
  const [apiBlogData, setApiBlogData] = useState(null)
  const [blogUrl, setBlogUrl] = useState({})
  const [loading, setLoading] = useState(true)
  const {id} = useParams() 

  useEffect(() => {

    getBlogSectionData()
      // setTimeout(() => {
      //   setLoading(false);
      // }, 500);

  }, []);

  const getBlogSectionData = () => { 
    let apiBlogReq = null
    const words = id.split(' ');
    axios
    .get(`/api/blogs`, {})
    .then((res) => {
      if (res.data.status === "success") {
        
        apiBlogReq = res.data.data.find((item) => item.slug_url == id)
        if(apiBlogReq)
        {
          setApiBlogData(apiBlogReq);
          setLoading(false);
          setBlogUrl({
            str_id:"",
            recentBlogs: {
              blog1: 'Exploring Histroy',
              blog1_str_id: 'exploring-the-history-and-techniques-of-the-long-jump-in-the-olympics',
              blog2: 'Benefits & Challenges',
              blog2_str_id: 'the-benefits-and-challenges-of-being-a-multisport-athlete',
            },
            dates: '30 April, 2023', 
            time:'2:00PM'
          })
        }
        else
        {
          if (words.length > 0) {
            const firstWord = words[0];
            let blog = BlogsList.find(element => element.str_id == firstWord)
            setBlogData(blog.detailArray)
            setBlogUrl(blog) 
            setLoading(false);
          }
        }

      }
      else{
        setLoading(false);
      }
    })

   
  }

  const InnerBlogModule = () => { 
    return ( 
      <div>
        {blogUrl.str_id == 'Benefits' && 
          <Helmet>
            <title>The Benefits and Challenges of Being a Multisport Athlete</title>
            <meta name="keywords" content="Multisport Athlete" />
            <link rel="canonical" href="https://www.questsportscanada.club/blogs/the-benefits-and-challenges-of-being-a-multisport-athlete" />
            <meta name="description" content="Discover the advantages and obstacles of being a multisport athlete. Explore the benefits and challenges faced by athletes engaging in multiple sports." />
          </Helmet>
        }
        {blogUrl.str_id == 'Exploring' && 
          <Helmet>
            <title>Exploring the History and Techniques of the Long Jump in the Olympics</title>
            <meta name="keywords" content="Olympics Long Jump" />
            <link rel="canonical" href="https://www.questsportscanada.club/blogs/exploring-the-history-and-techniques-of-the-long-jump-in-the-olympics" />
            <meta name="description" content="Uncover the captivating legacy and methods of the Olympic long jump, delving into its rich history and expert techniques. Join us on a journey of discovery." />
          </Helmet>
        }
        {blogUrl.str_id == 'Achieving' && 
          <Helmet>
            <title>Achieving Maximum Performance With a Speed Training Program</title>
            <meta name="keywords" content="Speed Training Program" />
            <link rel="canonical" href="https://www.questsportscanada.club/blogs/achieving-maximum-performance-with-a-speed-training-program" />
            <meta name="description" content="Unlock your athletic potential with our Speed Training Program. Achieve maximum performance and improve your speed with expert guidance and proven techniques. Start your journey today!" />
          </Helmet>
        }
        {blogUrl.str_id == 'Ontario' && 
          <Helmet>
            <title>Ontario Speed Training: Boost Your Performance with Quest Sports Ultimate Guide</title>
            <meta name="keywords" content="Ontario Speed Training" />
            <link rel="canonical" href="https://www.questsportscanada.club/blogs/ontario-speed-training-a-comprehensive-guide-to-enhancing-athletic-performance" />
            <meta name="description" content="Unlock your athletic potential with Quest Sports' comprehensive Ontario speed training guide. Enhance agility, speed, and endurance for peak performance." />
          </Helmet>
        }
        {blogUrl.str_id == 'Track' && 
          <Helmet>
            <title>Track and Field Success: Expert Tips & Strategies - Quest Sports</title>
            <meta name="keywords" content="Track and Field" />
            <link rel="canonical" href="https://www.questsportscanada.club/blogs/track-and-field-tips-and-strategies-for-success" />
            <meta name="description" content="Achieve greatness in track and field with expert tips and strategies. Discover how to excel in sprinting, jumping, and throwing. Get ahead with Quest Sports." />
          </Helmet>
        }
        {blogUrl.str_id == 'The' && 
          <Helmet>
            <title>The Role of Plyometrics in Sport Strength and Conditioning</title>
            <meta name="keywords" content="Sport Strength and Conditioning" />
            <link rel="canonical" href="https://www.questsportscanada.club/blogs/the-role-of-plyometrics-in-sport-strength-and-conditioning" />
            <meta name="description" content="Discover the impact of plyometrics in sport strength and conditioning. Enhance performance, power, and agility with proven techniques." />
          </Helmet>
        }
        {blogUrl.str_id == 'Ontarios' && 
          <Helmet>
            <title>Ontario's Leading Sports Organization Celebrates Unprecedented Achievement</title>
            <meta name="keywords" content="Sports Organization Celebrates" />
            <link rel="canonical" href="https://www.questsportscanada.club/blogs/ontarios-leading-sports-organization-celebrates-unprecedented-achievement" />
            <meta name="description" content="Ontario's top sports organization celebrates incredible success, setting new records and achieving unparalleled milestones. Join us in recognizing their remarkable achievements!" />
          </Helmet>
        }
        {blogUrl.str_id == 'How' && 
          <Helmet>
            <title>How to Stay Focused and Motivated During Track and Field Training?</title>
            <meta name="keywords" content="Track and Field Training" />
            <link rel="canonical" href="https://www.questsportscanada.club/blogs/how-to-stay-focused-and-motivated-during-track-and-field-training" />
            <meta name="description" content="Discover effective strategies for maintaining focus and motivation during track and field training. Stay on track towards your goals with these valuable tips!" />
          </Helmet>
        }
        {blogUrl.str_id == 'Common' && 
          <Helmet>
            <title>Common Mistakes to Avoid in Your Speed Training Program</title>
            <meta name="keywords" content="Speed Training Program" />
            <link rel="canonical" href="https://www.questsportscanada.club/blogs/common-mistakes-to-avoid-in-your-speed-training-program" />
            <meta name="description" content="Avoid common mistakes in your speed training program for better results. Maximize your training efficiency with expert tips. Achieve your speed goals faster." />
          </Helmet>
        }
        {blogUrl.str_id == '' && apiBlogData
          ?
          <Helmet>
            <title>{apiBlogData.metaTitle}</title>
            {apiBlogData.metaDescription && <meta name='description' content={apiBlogData.metaDescription} />}
              {apiBlogData.canonicalLink && <link rel="canonical" href={apiBlogData.canonicalLink}></link>}
          </Helmet>
          :
          <></>
        }
        <div className='quest_sp_inner_blogs_page'>
          <RegularHero 
            backgroundImage= {'/images/creatives/innerblog-hero.png'}
            text={''}  
          /> 
          <BlogInnerWrapper data={blogData} apiData={apiBlogData} recentBlogs={blogUrl} /> 
          <RadialImgContent 
            heading={`Join Quest Sports Track and Field Club and Experience Quality Athletic Development`}
            paragraph={`The Quest Professional Athletic Group founded Quest Sports Inc. The group is comprised of current and former professional athletes, to Olympians, 
            who unanimously believe that Canada has yet to tap into its true athletic talents. In an effort to introduce the Quest Training Systems to the world of 
            sport, Quest Sports Inc. has set forth a bold and passionate plan to identify sports talent in children from the playgrounds of Canada. 
            Our goal is to take them from their humble beginnings in the playground to the realization of their true athletic potential.`}
            buttonTitle={`REGISTER`}
            buttonRoute={'/appointment-booking'}
            buttonIcon='/images/icons/golden-torch.svg'
            hoverBtnIcon='/images/icons/black-torch.svg'
            imgUrl={`/images/creatives/teenage-girl-professional-runner.webp`}
          />
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      {loading ? <Loader /> : InnerBlogModule()}
    </Fragment>
  )
}

export default InnerBlog;