import React, { Fragment, useEffect, useState } from 'react'
import RegularHero from '../components/Hero-Section/RegularHero';
import UncuttedImg from '../components/Uncutted Image Content/UncuttedImg';
import PerformanceGroup from '../components/Performance Group/PerformanceGroup';
import Events from '../components/Events/Events';
import Associations from '../components/Associations Slider/Associations';
import RadialImgContent from '../components/Radial Image Content/RadialImgContent';
import Accomplishments from '../components/Accomplishments/Accomplishments';
import CutImageContent from '../components/Cutted Image Content/CutImageContent';
import Loader from '../components/Loader/Loader';
import { Helmet } from 'react-helmet';
import MembershipSlider from '../components/Memberships/MembershipSlider';
import { ElementaryMembershipsTrackFieldArr } from '../components/Constants/ElementaryMemberships';

const TrackField = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const renderImageBasedOnMode = (currImage) => { 
    if(currImage == '/images/creatives/indoor_track_field.jpg' && window.innerWidth <= 620) return '/images/creatives/indoor_track_field_mob.jpg';
    return currImage;
  };

  const TrackFieldModule = () => {
    return (
      <Fragment>
        <Helmet>
          <title>Best Track & Field Club in Ontario</title>
          <meta name="keywords" content="Track And Field Training In Ajax" />
          <link
            rel="canonical"
            href="https://www.questsportscanada.club/track-field-club"
          />
          <meta
            name="description"
            content="Are you looking for track and field training to take your career to the next level Our athletic training academy in Ontario offers specialized programs for sprinters, jumpers, throwers, and distance runners "
          />
        </Helmet>

        <div className="quest_sp_track_field_page">
          <RegularHero
            breakLine={true}
            endHighlightedText={true}
            paraTrackField={true}
            backgroundImage={renderImageBasedOnMode(`/images/creatives/indoor_track_field.jpg`)}
          />
          <UncuttedImg
            targetSection={"#track_field_membership_slider"}
            trackField={true}
            imgURL={`/images/creatives/uncutted-img-content-1.png`}
          />
          <Accomplishments
            backgroundImage={"/images/creatives/accomplishment-banner.png"}
          />
          <RadialImgContent
            heading={`Join Quest Sports Track and Field Club and Experience Quality Athletic Development`}
            paragraph={`The Quest Professional Athletic Group founded Quest Sports Inc. The group is comprised of current and former professional athletes, to Olympians, 
            who unanimously believe that Canada has yet to tap into its true athletic talents. In an effort to introduce the Quest Training Systems to the world of 
            sport, Quest Sports Inc. has set forth a bold and passionate plan to identify sports talent in children from the playgrounds of Canada. 
            Our goal is to take them from their humble beginnings in the playground to the realization of their true athletic potential.`}
            buttonTitle={`BECOME A MEMBER`}
            removeBtn={true}
            targetSection={"#track_field_membership_slider"}
            programsRoute={true}
            buttonIcon="/images/icons/golden-torch.svg"
            hoverBtnIcon="/images/icons/black-torch.svg"
            imgUrl={`/images/creatives/teenage-girl-professional-runner.webp`}
          />
          <CutImageContent
            heading={`Running Events`}
            paragraph={`Quest Sports Canada’s Track and Field Club believes that speed is essential for all track and field events.`}
            buttonTitle={`SIGN UP`}
            buttonRoute={"/track-field-club"}
            removeBtn={true}
            programsRoute={true}
            rowItems={true}
            targetSection={"#track_field_membership_slider"}
            rowListItemHeading3={"DISTANCE"}
            rowListItemHeading1={"SPRINTS"}
            rowListItemHeading2={"HURDLES"}
            rowListItems3={["Middle & Long Distance"]}
            rowListItems1={["100 meters", "200 meters", "400 meters"]}
            rowListItems2={[
              "60m to 100m (girls)",
              "60m to 110m (boys)",
              "200m to 400m long hurdles",
            ]}
            rowListItemHeading4={"RELAYS"}
            rowListItems4={["4 X 100 & 4 X 400"]}
            reverse={`flex-row-reverse`}
            imgUrl={`/images/creatives/img-content-running.png`}
          />
          <CutImageContent
            heading={`SPEED DEVELOPMENT`}
            paragraph={`At Quest Sports, we are all about speed development`}
            DashedPara1={`We believe consistent work will change the game for athletes who     wish to take their training to the next level.`}
            DashedPara2={`We know that accelerating quickly and powerfully is probably the most important skill that needs improvement in all athletes.`}
            DashedPara3={`We teach all the movement patterns to condition the neuromuscular system to improve the firing patterns of fast-twitch muscle fibres.`}
            buttonTitle={`SIGN UP`}
            buttonRoute={"/track-field-club"}
            targetSection={"#track_field_membership_slider"}
            removeBtn={true}
            programsRoute={true}
            imgUrl={`/images/creatives/img-content-18.png`}
          />
          <CutImageContent
            heading={`HIGH JUMP`}
            paragraph={`High Jump Quest Sports Canada’s Track and Field Club focuses on two main jumping events: The long jump and the high jump. Conditioning programs are put in place to support these events.`}
            //  breakParagraph={`Quest Sports Performance Group (QSPG) is one of Ontario's premier youth and junior development programs. We have designed a sports culture that supports athletes who wish to train and develop beyond their expectations. We develop athletes from the ground up, covering all aspects of athletic development.`}
            buttonTitle={`SIGN UP`}
            buttonRoute={"/track-field-club"}
            targetSection={"#track_field_membership_slider"}
            reverse={"flex-row-reverse"}
            removeBtn={true}
            programsRoute={true}
            imgUrl={`/images/creatives/img-content-6.png`}
          />
          <CutImageContent
            heading={`LONG JUMP`}
            paragraph={`Quest Sports Canada’s Track and Field Club focuses on two main jumping events: The long jump and the high jump. Conditioning programs are put in place to support these events.`}
            //  breakParagraph={`Quest Sports Performance Group (QSPG) is one of Ontario's premier youth and junior development programs. We have designed a sports culture that supports athletes who wish to train and develop beyond their expectations. We develop athletes from the ground up, covering all aspects of athletic development.`}
            buttonTitle={`SIGN UP`}
            removeBtn={true}
            programsRoute={true}
            targetSection={"#track_field_membership_slider"}
            buttonRoute={"/track-field-club"}
            imgUrl={`/images/creatives/img-content-7.png`}
          />
          <CutImageContent
            heading={`THROW EVENTS`}
            paragraph={`Our club also focuses on the following throwing events: Javelin, Shot Put, & Discuss. A strength and conditioning program is put in place to support these events.`}
            buttonTitle={`SIGN UP`}
            buttonRoute={"/track-field-club"}
            reverse={"flex-row-reverse"}
            removeBtn={true}
            targetSection={"#track_field_membership_slider"}
            programsRoute={true}
            imgUrl={`/images/creatives/img-content-16.png`}
          />
          <CutImageContent
            heading={`COMBINED EVENTS`}
            paragraph={`DAY 1: 100 hurdles, high jump, shot put, 200 meters. `}
            buttonTitle={`SIGN UP`}
            buttonRoute={"/track-field-club"}
            targetSection={"#track_field_membership_slider"}
            highlightedParagraph={`Women's Heptathlon`}
            removeBtn={true}
            programsRoute={true}
            highlightedParagraph2={`Men Decathlon`}
            breakParagraph={`DAY 2: Long jump, javelin and 800 meters`}
            paragraph2={`DAY 1: 100m, long jump, shot put, high jump and 400m. `}
            breakParagraph2={`DAY 2: 110m hurdles, discus, pole vault, javelin and 1500m`}
            imgUrl={`/images/creatives/img-content-17.jpg`}
          />
          <PerformanceGroup targetSection={"#track_field_membership_slider"} />
          <Events />
          <Associations />
          <MembershipSlider
            id={"track_field_membership_slider"}
            backgroundImage={`/images/creatives/speed-training-membership.png`}
            membershipsType={ElementaryMembershipsTrackFieldArr}
            mainHeading={"SIGNUP FOR ELEMENTARY & HIGHSCHOOL MEMBERSHIPS"}
          />
        </div>
      </Fragment>
    );
  }
  return (
    <Fragment>
      {loading ? <Loader /> : TrackFieldModule()}
    </Fragment>
  )
}

export default TrackField;